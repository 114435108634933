<template>
    <div class="flex flex-col justify-center mt-16 sm:px-6 lg:px-8 md:mt-32 lg:mt-48">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <router-link :to="{ name: 'Home', path: '/home' }" exact>
                <img class="w-auto h-16 mx-auto" src="@/assets/logo-on-white-excess.png" alt="logo" />
            </router-link>
        </div>

        <div :validation-schema="validationSchema">
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
                    <form class="space-y-6" action="#" method="POST">
                        <div class="w-full flex flex-col">
                            <label for="email" class="block text-sm font-medium text-primary-700">
                                Email
                            </label>

                            <input
                                class="input-block"
                                type="email"
                                v-model="credentials.email"
                                placeholder="Email"
                                @keyup.enter="login()"
                            />
                        </div>

                        <div class="w-full flex flex-col">
                            <label for="password" class="block text-sm font-medium text-primary-700">
                                Password
                            </label>

                            <input
                                class="input-block"
                                type="password"
                                v-model="credentials.password"
                                placeholder="Password"
                                @keyup.enter="login()"
                            />
                        </div>

                        <div>
                            <div class="float-left px-2 py-2 text-sm text-red-600" v-if="message">
                                <span class="font-semibold">Error!</span>
                                {{ message }}
                            </div>
                            <button
                                @click="login"
                                :disabled="loading"
                                type="submit"
                                class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white uppercase border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { defineRule } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import store from '../store';
import { useRouter } from 'vue-router';
import AuthAPI from '../api/auth';

defineRule('required', required);
defineRule('email', email);

export default defineComponent({
    name: 'Login',

    setup() {
        const router = useRouter();

        const message = ref(null);
        const loading = ref(false);
        const credentials = reactive({
            email: '',
            password: '',
        });

        const login = async () => {
            message.value = null;

            loading.value = true;
            AuthAPI.login(credentials)
                .then(async () => {
                    const { data } = await AuthAPI.user();
                    store.commit('SET_USER', data);
                    if (process.env.VUE_APP_ETH_NODE) {
                        await store.commit('SET_WALLET', data.ethwallet);
                        store.dispatch('createProvider', process.env.VUE_APP_ETH_NODE);
                    }

                    if (router.currentRoute.value.path !== '/') {
                        router.push('/');
                    }
                    loading.value = false;
                })
                .catch(err => {
                    message.value = err.response.data.message;

                    loading.value = false;
                });
        };

        const validationSchema = computed(() => {
            return {
                email: 'required|email',
                password: 'required',
            };
        });

        return {
            credentials,

            login,
            message,
            loading,
            validationSchema,
        };
    },
});
</script>

<style lang="postcss" scoped>
.help-link {
    @apply text-primary-700 font-medium;
}

.help-link:hover {
    @apply text-primary-800 underline;
}
</style>
